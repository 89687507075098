import React      from "react";

import Seo      from '../components/atoms/seo/seo';
import Layout   from '../components/layout';
import Headline from '../components/atoms/headline/headline';
import Stage    from '../components/molecules/stage/stage';

export default ({ data }) => (
  <Layout siteTitle="Kontakt" theme="blue">
    <Seo
      title="Kontakt"
      description="Schreib mir eine Nachricht"
      canonical="impressum"
    />
    <Stage fullHeight={true} styleFull="primary">
      <Headline>Kontakt</Headline>

      <Headline tag="h2">E-Mail</Headline>
      <p>info@lean-works.de</p>

      <Headline tag="h2">Socialmedia</Headline>
      <p>
        <a title="Xing" href="https://www.xing.com/profile/Bjoern_Biege">Xing</a> |
        <a title="LinkedIn" href="https://www.linkedin.com/in/bj%C3%B6rn-biege-637aa8106/">LinkedIn</a> |
        <a title="Github" href="https://github.com/freesh">Github</a>
      </p>
    </Stage>
  </Layout>
)
